@import "./variables.scss";

.cfm-logo-container {
    padding-top    : 2rem;
    display        : flex;
    justify-content: space-between;
    padding        : 2rem;

    .cfm-vote-image {
        width : 8rem;
        height: 100%;

        //    width: 32%;
        //    height: 32%;
    }

    .cfm-vote-text {
        color         : white;
        width         : 8rem;
        padding-right : 1rem;
        padding-top   : 4.1rem;
        padding-bottom: 0;
        margin-bottom : 0;
        text-align    : center;
        font-size     : 20PX;
        // padding-top: 14%;
        // font-size: 15px;
        // width: 35%;
    }

    .cfm-layout-vote-image {
        width : 30%;
        height: 100%;
    }
}

.cfm-path-container {
    position    : relative;
    padding-left: 3rem;

    .cfm-path-image {
        width   : 7rem;
        position: absolute;
    }

    .cfm-path-text {
        color       : white;
        font-size   : 27px;
        padding-left: 1.8rem;
        margin-top  : -.9rem;
    }
}

.cfm-music-container {
    position  : relative;
    margin-top: -10%;

    .cfm-music-logo {
        width   : 100%;
        position: absolute;

    }

    .cfm-music-mobile {
        position         : absolute;
        width            : 28%;
        padding-top      : 3rem;
        padding-left     : 35%;
        -moz-animation   : blink normal 2s infinite ease-in-out;
        /* Firefox */
        -webkit-animation: blink normal 2s infinite ease-in-out;
        /* Webkit */
        -ms-animation    : blink normal 2s infinite ease-in-out;
        /* IE */
        animation        : blink normal 2s infinite ease-in-out;
        /* Opera and prob css3 final iteration */

    }

}

@keyframes blink {
    0% {
        opacity: .1;
    }

    20% {
        opacity: .2;
    }

    50% {
        opacity: .5;
    }

    80% {
        opacity: .8;
    }

    100% {
        opacity: 1;
    }
}

.cfm-bottom-container {
    text-align: center;

    padding-top: 0rem;
    margin-top : 60%;

    .cfm-bottom-button {
        border          : none;
        background-color: #fff000;
        padding-left    : 2rem;
        padding-top     : 6px;
        padding-right   : 2rem;
        padding-bottom  : 6px;
        border-radius   : 20px;
    }
}

.cfm-bottom-container:active {
    transform: translateY(4px);

}

.cfm-bottom-image {
    // position: absolute;
    bottom: 0;

    // margin-top: 4.5rem;
    img {
        width         : 90%;
        max-height    : 100%;
        // margin-left: 2rem;
        margin-top    : 4%;
    }
}

.cfm-box {
    .cfm-logo-new-container {
        padding-top  : 10%;
        padding-left : 10%;
        padding-right: 10%;

        display        : flex;
        align-items    : flex-end;
        justify-content: space-between;

        .cfm-logo-img {
            width: 33%;

            .cfm-playlist-img {
                margin-top: 25%;
            }
        }

        .cfm-logo-text {
            width        : 7rem;
            text-align   : center;
            margin-bottom: 18%;

            p {
                font-size: 1.1rem;
            }
        }
    }

    .cfm-music-new-container {
        // position: relative;
        text-align: center;

        img {
            // position: absolute;
        }

        .cfm-mobile-img {
            width: 20%;
        }

        button {
            background-color   : $secondaryColor;
            padding            : 2% 8% 2% 8%;
            border-radius      : 20px;
            border             : none;
            // font-size       : 16px;
            font-weight: bold;
        }
        button:active{
            transform: translateY(4px);

        }
    }

    .cfm-bottom-new-container {
        margin-top : 15%;
        display    : flex;
        height     : 100%;
        align-items: flex-end;

        img {
            // margin-top: 10%;
        }
    }

    .cfm-how-container {
        display        : flex;
        justify-content: center;

        .cfm-how-img {
            width: 30%;
        }
    }
}


.cfm-layout-path-container {
    position: relative;

    .cfm-layout-path-image {
        position: absolute;
        width   : 7.5rem;
    }

    .cfm-layout-path-text {
        color      : white;
        font-size  : 25px;
        margin-top : 11px;
        margin-left: -19px;

    }
}


.cfm-layout-path-full-container {
    width: 26%;
}

.cfm-alert-contaier-image {
    position       : absolute;
    height         : 100vh;
    display        : flex;
    justify-content: center;
    align-items    : center;
    width          : 100vw;
}

.cfm-alert-box-container {
    text-align: center;
    position  : relative;

    .cfm-alert-box-text {
        position: absolute;
        width   : 85%;

        h4 {
            color        : $secondaryColor;
            padding-left : 8%;
            margin-bottom: 0;
            font-size    : 30px;
            padding-top  : 6%;
        }

        ul {
            padding-left: 35%;

            li {
                font-size: 25px;
            }
        }
    }

    .cfm-alert-box-image {
        width: 70%;
    }

    .cfm-alert-read {

        color          : gray;
        padding-left   : 31%;
        margin-top     : -7px;
        font-size      : 18px;
        text-decoration: none;

    }

    .cfm-alert-image-close {
        width       : 6%;
        float       : right;
        margin-right: 10%;
        margin-top  : 5%;
    }
}


.cfm-register-container {
    padding-right: 21%;
    padding-left : 21%;
    margin-top   : 8%;

    .cfm-register-form {
        margin-top: 3%;
    }

    label {
        color    : white;
        font-size: 16px;
    }

    input {
        width           : 100%;
        margin-top      : 4px;
        background-color: #262626;
        border          : none;
        height          : 25px;
        border-radius   : 2px;
        padding-left    : 10px;
        color           : white;
        // color: white;
    }
}



.cfm-playlist-container {
    height        : 82%;
    overflow      : auto;
    padding       : 10%;
    padding-right : 5%;
    padding-bottom: 3%;
    padding-left  : 3%;
}

.cfm-playlist-component-container {
    .cfm-playlist-component-container-div {
        display: flex;
        width  : 100%;
        height : 3rem
    }

    .cfm-playlist-close {
        font-size: 1rem;
    }

    .cfm-playlist-add {
        font-size: 1rem;
    }
}

.cfm-playlist-submit-container {
    text-align    : center;
    padding-bottom: 20px;

    .cfm-playlist-button {
        padding-left : "2rem";
        padding-right: "2rem";
        background   : rgb(46, 46, 46);
        background   : linear-gradient(0deg, rgba(46, 46, 46, 1) 9%, rgba(71, 71, 71, 1) 52%, rgba(84, 84, 84, 1) 73%);
        color        : $secondaryColor
    }
}

.cfm-terms-list {
    padding-left: 5%;
    color       : white;
    font-size   : 18px
}

.fb,
.yt,
.insta,
.twitter {
    cursor: pointer;
}

// .fb:hover {
//     color: blue;
//     width: 65% !important;
//     cursor: pointer;
// }

.cfm-errorpage-container {
    color          : white;
    height         : 23rem;
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-left    : 34%;
    font-size      : 30px;
    // margin-top     : 1rem;
}

.cfm-container-terms {
    ol {
        li {
            font-size  : 14px;
            padding-top: 5px;
        }
    }
}

.cfm-vote-text-brand-icon-arrow{
    size: 16;
}