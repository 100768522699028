@import "./variables.scss";

// @media screen and (min-width: 320px) and (max-width: 380px) {
//     .cfm-bottom-image{
     
//         display: none;
//         img{
//             width: 100%;
//             // margin-left: 2rem;
//         }
//     }
// }

@media screen and (max-width: 720px) and (orientation: landscape) {
    .cfm-errorpage-container{
        margin-left: 45%;
    }
    .container{
        margin-left: 30%;
        margin-right: 30%;
    }
    .cfm-logo-container{
        padding-top: 2rem;
        display: flex;
        justify-content: space-between;
        padding: 2rem;
        padding-bottom: 1.5rem;
        .cfm-vote-image{
            width: 30%;
            // width: 47%;
        }
        .cfm-vote-text{
            color: white;
            width: 4rem;
            padding-right: 1rem;
            padding-top: 1.4rem;
            padding-bottom: 0;
            margin-bottom: 0;
            text-align: center;
            font-size: 10px;
        }
    }

    .cfm-path-container{
        position: relative;
        padding-left: 3rem;
        .cfm-path-image{
            width: 2.6rem;
            position: absolute;
        }
        .cfm-path-text{
            color: white;
            font-size: 10px;
            padding-left: 2.5rem;
            margin-top: -1.12rem;
        }
    }

      
    .cfm-music-container{
        position: relative;
        margin-top: -2rem;
        .cfm-music-logo{
            width: 100%;
            position: absolute;

        }
        .cfm-music-mobile{
            position: absolute;
            width: 24%;
            padding-top: 2rem;
            padding-left: 38%;
        }
    }

    .cfm-alert-box-container{
        text-align: center;
        position: relative;
        .cfm-alert-box-text{
            position:absolute;
            width:75%;
            h4{
                color: $secondaryColor;
                padding-left: 35%;
                margin-bottom: 0;
                font-size: 18px;
                padding-top: 3%;
            }
            ul{
                padding-left: 45%;
     li{
         font-size: 15px;
         font-weight: 300;
     }
            }
        }
        .cfm-alert-box-image{
            width: 55%;
        }
    
        .cfm-alert-read{
            
        color: gray;
        padding-left: 31%;
        margin-top: -7px;
        font-size: 10px;
    
        }
    
        .cfm-alert-image-close{
            width: 6%;
            float: right;
            margin-right: 5%;
            margin-top: 5%;
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .container{
        position: relative;
        // height: 100vh;
            width: 100vw;

    }
    .cfm-container {
        margin-left : 0%;
        margin-right: 0%;
    }

    .cfm-box {
        height: 33.33vh;
   
    }

    .cfm-logo-new-container {
        padding        : 35px;
        display        : flex;
        justify-content: space-between;

        .cfm-logo-img {
            width: 35%;
        }
    }
    .cfm-logo-container{
        padding-top: 2rem;
        display: flex;
        justify-content: space-between;
        padding: 2rem;
        padding-bottom: 1.5rem;
        .cfm-vote-image{
            width: 28%;
            // width: 47%;
        }
        .cfm-vote-text{
            color: white;
            width: 7rem;
            padding-right: 1rem;
            padding-top: 1.4rem;
            padding-bottom: 0;
            margin-bottom: 0;
            text-align: center;
            font-size: 17px;
        }
    }
    
    .cfm-path-container{
        position: relative;
        padding-left: 3rem;
        .cfm-path-image{
            width: 4rem;
            position: absolute;
        }
        .cfm-path-text{
            color: white;
            font-size: 16px;
            padding-left: 2.3rem;
            margin-top: -1rem;
        }
    }
    
    .cfm-music-container{
        position: relative;
        margin-top: -3rem;
        .cfm-music-logo{
            width: 100%;
            position: absolute;

        }
        .cfm-music-mobile{
            position: absolute;
            width: 28%;
            padding-top: 2rem;
            padding-left: 36%;
        }
    }

    .cfm-bottom-container {
        text-align: center;
        
        padding-top: 0rem;
        margin-top: 56%;
        .cfm-bottom-button{
            border: none;
            background-color: #fff000;
            padding-left: 1.5rem;
            padding-top: 6px;
            padding-right: 1.5rem;
            padding-bottom: 6px;
            border-radius: 20px;
        }
    }

    .cfm-bottom-image{
        // position: relative;
        // bottom: -3px;
        // width: 400px;
        // height: 315px;
        img{
            // width: 100%;
            // position: absolute;
            bottom: 0;
            padding-top: 6%;
            // margin-left: 2rem;
        }
    }
    .cfm-layout-path-container{
        position: relative;
        .cfm-layout-path-image{
            width: 100%;
            position: absolute;
            padding-left: 12%;
        }
        .cfm-layout-path-text{
            color: white;
            font-size: 14px;
            // padding-left: 1.8rem;
            margin-top: 4px;
        }
    }
    .cfm-layout-path-full-container{
        width: 24%;
    }  
    .cfm-alert-contaier-image{
        position: absolute;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }  

    .cfm-alert-box-container{
        text-align: center;
        position: relative;
        .cfm-alert-box-text{
            position:absolute;
            width:80%;
            h4{
                color: $secondaryColor;
                padding-left: 20%;
                margin-bottom: 0;
                font-size: 18px;
                padding-top: 2%;
            }
            ul{
                padding-left: 35%;
     li{
         font-size: 15px;
         font-weight: 300;
     }
            }
        }
        .cfm-alert-box-image{
            width: 88%;
        }
    
        .cfm-alert-read{
            
        color: gray;
        padding-left: 31%;
        margin-top: -7px;
        font-size: 10px;
    
        }
    
        .cfm-alert-image-close{
            width: 6%;
            float: right;
            margin-right: 5%;
            margin-top: 5%;
        }
    }
    .cfm-register-container{
        padding-right: 21%;
        padding-left:21%;
        .cfm-register-form {
            margin-top: 4%;
        }
        label{
            color: white;
            font-size: 15px;
        }
        input[type=text]{
            width: 100%;
            margin-top: 4px;
            background-color: #262626 ;
            border: none;
            height: 26px;
            border-radius: 2px;

            // color: white;
        }
    }

    .cfm-playlist-container{
        padding: 10%;
        height: 82%;
        overflow:auto;
        padding-right: 5%;
        padding-bottom: 3%;
        padding-left: 8%;

    }
    .cfm-playlist-submit-container{
        text-align: center;
        padding-bottom: 20px;
        .cfm-playlist-button{
            padding-left: "2rem";
            padding-right: "2rem";
            background: rgb(46,46,46);
            background: linear-gradient(0deg, rgba(46,46,46,1) 9%, rgba(71,71,71,1) 52%, rgba(84,84,84,1) 73%);
             color: $secondaryColor
        }
    }
    .cfm-terms-list{
        padding-left: 6%;
        color:white;
        font-size:12px
    }
    
    .cfm-errorpage-container {
        color: white;
        height: 30vh;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 34%;
        font-size: 14px;
        margin-top: 0%;
    }
}


@media screen and (min-width: 1441px) and (max-width: 2560px) {

    .cfm-box .cfm-logo-new-container .cfm-logo-text
    {
        width: 15rem;
    }
    .cfm-box .cfm-logo-new-container .cfm-logo-text p{
        font-size: 2.1rem;
    }
    .cfm-container-terms ol li{
        font-size: 2rem;
    }
    .cfm-container-text-brand-h4{
        font-size: 2.2rem;
    }
    .cfm-box .cfm-music-new-container button{
        // font-size: 1.3rem;
    }

}