@import "../src/styles/variables.scss";
@import "../src/styles/globals.scss";

body {
  margin:0;
  font-family:  'Noto Sans Malayalam','Roboto','sans-serif';
  background-color: #3d3d3d;
  overflow-x: hidden;
}

button{
  font-family:  'Noto Sans Malayalam','Roboto','sans-serif';

}

input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


// html {
//   -webkit-text-size-adjust: none
// }

@font-face {
  font-family : 'Noto Sans Malayalam';
  font-weight : normal;
  font-style  : normal;
  font-display: auto;
  src         : local('Noto Sans Malayalam'),
      url('//css.mathrubhumi.com/font/NotoSansMalayalam-Regular.woff2') format('woff2'),
      url('//css.mathrubhumi.com/font/NotoSansMalayalam-Regular.woff') format('woff')
}

.malayalam {
  font-family: 'Noto Sans Malayalam', Arial, sans-serif;
}

.english {
  font-family: 'Roboto', 'Noto Sans Malayalam';
}


/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"], 
  input[type="tel"], input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="tel"], input[type="url"]{ font-size: 16px; }
}