.cfm-page {
    background-color: $primaryColor;
    width           : 100vw;
    height          : 100%;
}

.container {
    margin-left : 30%;
    margin-right: 30%;
    // position: relative;
    height      : 100%;
}

.cfm-vote-text-brand {
    color: $secondaryColor;
}

/* width */
::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow   : inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background   : #232323;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #232323;
}

button {
    cursor: pointer;
}

@media screen and (min-device-width: 320px) and (max-width: 480px) {
    .container {
        margin-left : 0%;
        margin-right: 0%;
        position    : relative;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px) {



    .cfm-logo-container  {

        .cfm-vote-image {
            width: 2rem;

        }
    }

}



img {
    width: 100%;
}

.cfm-box {
    height: 33.33%;
}

.cfm-white {
    color: white;
}

.cfm-brand {
    color      : $secondaryColor;
    font-weight: 500;
}

@media screen and (min-width: 768px) and (max-width: 882px) {
    .container {
        margin-left : 2%;
        margin-right: 2%;

    }

}

@media screen and (min-device-width:768px) and (max-width: 1024px) {
    .container {
        margin-left : 5%;
        margin-right: 5%;

        .cfm-box {
            .cfm-logo-new-container {
                .cfm-logo-text {
                    width        : 13rem;
                    margin-bottom: 14%;

                    p {
                        font-size: 2rem;
                    }
                }
            }

            .cfm-music-new-container {
                button {
                    font-size: 18px;

                }
            }
        }

        .cfm-register-container {
            label {
                font-size: 30px;
            }

            input {
                height   : 50px;
                font-size: 25px;
            }

            span {
                font-size: 25px;
            }
        }

        .cfm-bottom-container {
            .cfm-bottom-button {
                font-size: 25px;
            }

        }

        .cfm-playlist-component-container {
            .cfm-playlist-component-container-div {

                height: 6rem;

                h3 {
                    font-size: 30px;
                }

                p {
                    font-size: 25px;
                }
            }

            .cfm-playlist-close {
                font-size: 3rem;
            }

            .cfm-playlist-add {
                font-size: 3rem;
            }
        }
     
    }


    .cfm-alert-box-container {
        .cfm-alert-box-image {
            width: 94%;
        }
        .cfm-errorpage-container{
            // height: 15vh;
        }
    }

    #thanks-tune{
        // padding-left: 5.5rem;
    }

}

@media screen and (min-width: 1025px) {
    #thanks-tune{
        // padding-left: 3.5rem;
    }
}